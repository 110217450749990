<template>
  <div class="quillEditorContainer">
    <div class="quillEditor">
      <h1 class="mb-2">Informações</h1>
      <VueEditor 
        v-model="form.ingressos"
        ref="refQuillEditor"
        :options="quillOptions"
      >
    </VueEditor >

    <h1 class="mt-3">Ponto de Vendas</h1>

    <VueEditor  class="mt-3"
        v-model="form.ponto_vendas"
        ref="refQuillEditor"
        :options="quillOptions"
      >
    </VueEditor >
    <div class="row justify-content-end mt-5">
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  {{ lista_campos == "" ? "Salvar" : "Atualizar" }}
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div>
    </div>
  </div>
</template>
<script>
  import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: [ fireAlert],

  name: 'VueQuillEditorComponent',
  data(){
    return {
      verif:false,
      form:{
      ingressos: '',
      ponto_vendas: '',
      },

      quillOptions: {
          placeholder: 'Digite uma frase para personalizar',
          readonly: false,
      }
    }
  },
  created(){this.preenxerCampor()},
  computed: {
    mensagem_alert() {
      return this.$store.state.evento.mensagem_alert;
    },

    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
  },
 
    // saveText(){
    //   localStorage.setItem('content', this.contentHtml)
    // },
    methods:{
      async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un Perfil no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
    this.form.id = this.lista_campos.id;
      await this.$store.dispatch("evento/create_evento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "evento",
      });
    },
    async update() {
      this.verif = true;
      this.form.id = this.lista_campos.id;
   
      await this.$store.dispatch("evento/update_evento", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "evento",
      });
    },

    preenxerCampor() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          ingressos: this.lista_campos.ingressos,
      ponto_vendas: this.lista_campos.ponto_vendas,
       
        };
    },
    

  },
}
 
</script>
<style scoped>
.buttonSave{
  background-color: blue;
  width: 60px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid blue;
  color: white;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.quillEditor {
  width: 100%;
  display: flex;
  flex-direction: column;
}
 
.quillEditorContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
 
</style>