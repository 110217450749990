<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <v-card elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">
              {{ lista_campos != "" ? "Editar |" : "Criar |" }} Evento
            </h3>
          </h3></v-card-title
        >
        <div class="card-body table-responsive">
          <div class="col-md-12">
            <div class="form">
              <div class="row form-group">
                <h4 class="col-md-12">Evento:</h4>

                <div class="col-md-4">
                  <label for="nome" class="col-md-12">Nome do Evento:*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="nome"
                    v-model="form.nome_evento"
                    placeholder="Digite o nome..."
                  />
                </div>
                <div class="col-md-3">
                  <label for="sobrenome" class="col-md-12"
                    >Produtora ou Produtor:*</label
                  >
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="sobrenome"
                    v-model="form.produtora_produtor"
                    placeholder="Digite o Sobrenome..."
                  />
                </div>

                <div class="col-md-3">
                  <label for="cpf" class="col-md-12">CPF ou CNPJ:*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="cpf"
                    v-mask="variableWithMask"
                    v-model="cpf_cnpj1"
                    placeholder="Digite o cpf ou Cnpj"
                  />
                </div>
                <div class="col-md-2">
                  <label for="cpf" class="col-md-12">Classificacao:*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="classificacao"
                    v-model="form.classificacao"
                    placeholder="Digite a classificação"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-md-12">Genero:*</label>
                  <treeselect
                    :multiple="true"
                    :options="lista_categorias"
                    placeholder="Selecione o Genero..."
                    v-model="form_cat.categorias"
                    @input="onInput"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-md-12">Parceiros:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_parceiros"
                    placeholder="Selecione o Parceiro..."
                    v-model="form.parceiros_id"
                    @input="onInput"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-md-12">Produtor:*</label>
                  <treeselect
                  :multiple="true"
              
                    :options="lista_produtores"
                    placeholder="Selecione o Parceiro..."
                    v-model="form.produtor_id"
                    @input="onInput"
                  />
                </div>
              </div>

              <div class="row form-group">
                <h4 class="col-md-12">Contato:</h4>

                <div class="col-md-6">
                  <label>Email:*</label>
                  <input
                    placeholder="Digite o seu E-mail"
                    v-model="form.email"
                    class="form-control"
                  />
                </div>

                <div class="col-md-6">
                  <label for="telefone1">Telefone 1:*</label>
                  <input
                    type="text"
                    class="form-control"
                    id="telefone1"
                    v-model="form.cel"
                    placeholder="Digite o telefone/celular..."
                  />
                </div>
              </div>
              <div class="row form-group">
                <h4 class="col-md-12">Endereço:</h4>

                <!-- <div class="col-md-3">
              <label for="pais" class="col-md-12">País:*</label>
              <select
                v-model="form.pais_id"
                class="form-control"
              >
                <option></option>
                <option value="1">Brasil</option>
              </select>
            </div>
           
            -->
                <div class="col-md-4">
                  <label class="col-md-12">Pais:*</label>
                  <treeselect
                    required=""
                    :multiple="false"
                    :options="[
                      { id: 1, label: 'Brasil' },
                      { id: 28, label: 'Paraguai' },
                    ]"
                    placeholder="Selecione o Pais..."
                    v-model="pais_id"
                    @input="onInput2"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-md-12">Estado:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_estados"
                    placeholder="Selecione o Estado..."
                    v-model="form.estados_id"
                    @input="onInput"
                  />
                </div>
                <div class="col-md-4">
                  <label class="col-md-12">Cidade:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_cidades"
                    placeholder="Selecione a Cidade..."
                    v-model="form.cidades_id"
                  />
                </div>
                <div class="col-md-2">
                  <label class="col-md-12">CEP:*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    v-model="form.cep"
                    placeholder="cep ...."
                  />
                </div>
                <div class="col-md-5">
                  <label class="col-md-12">Endereço:*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    v-model="form.endereco"
                    placeholder="Rua Bairro"
                    @input="loc"
                  />
                </div>

                <div class="col-md-2">
                  <label for="endereco_numero" class="col-md-12"
                    >Numero:*</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="endereco_numero"
                    v-model="form.endereco_numero"
                    placeholder="numero ...."
                  />
                </div>
                <div class="col-md-3">
                  <label for="nome_local_evento" class="col-md-12"
                    >Nome do Local do Evento:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="nome_local_evento"
                    v-model="form.nome_local_evento"
                    placeholder="Digite o complento..."
                  />
                </div>
                <div class="col-md-3 mt-2">
                  <label class="col-md-12"> data Evento</label>
                  <b-form-datepicker
                    v-model="form.data_evento"
                    class="mb-2"
                  ></b-form-datepicker>
                </div>
                <div class="col-md-3 mt-2">
                  <label class="col-md-12"> Se tiver mais dias </label>
                  <b-form-datepicker
                    v-model="form.data_mais"
                    class="mb-2"
                  ></b-form-datepicker>
                  <input type="radio" v-model="form.tde" value="1" /> e
                  <input type="radio" v-model="form.tde" value="2" /> a
                </div>

                <div class="col-md-3 mt-2">
                  <label class="col-md-12"> Hora Evento</label>

                  <b-form-timepicker
                    v-model="form.horario_evento"
                    locale="en"
                  ></b-form-timepicker>
                </div>
                <div class="col-md-3 mt-2">
                  <label class="col-md-12"> data desativação</label>
                  <b-form-datepicker
                    id="example-datepicker2"
                    v-model="form.date_hide"
                    class="mb-2"
                  ></b-form-datepicker>
                </div>
                <div class="col-md-3 mt-2">
                  <label class="col-md-12"> hora desativação</label>

                  <b-form-timepicker
                    v-model="form.hora_hide"
                    locale="en"
                  ></b-form-timepicker>
                </div>
              </div>

              <div class="row form-group">
                <h4 class="col-md-12">Minino Serviços:</h4>

                <div class="col-md-3">
                  <label for="valor minimo" class="col-md-12">
                    Valor minimo:</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.valor_minimo"
                    placeholder="Cobrança"
                  />
                </div>
                <div class="col-md-2">
                  <label for="porcetagem" class="col-md-12"
                    >Em caso de % :</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.porcetagem"
                    placeholder="porcetagem"
                  />
                </div>
                <div class="col-md-4">
                  <label for="valor_unitario" class="col-md-12"
                    >Em caso ingresso emitido Valor unitario :</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.valor_unitario"
                    placeholder="Valor Unitario"
                  />
                </div>

                <div class="col-md-3">
                  <label for="Serviços" class="col-md-12"> Taxa Debito:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.taxa_cartao_debito"
                    placeholder="taxa Cartao Debito"
                  />
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-3">
                  <label for="Serviços" class="col-md-12"> Taxa Credito:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.taxa_cartao_credito"
                    placeholder="taxa Cartao Credito"
                  />
                </div>

                <div class="col-md-3">
                  <label for="valor_unitario" class="col-md-12"
                    >Quantidade de PDV :</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.quant_pdv"
                    placeholder="Quantidade de PDV"
                  />
                </div>
                <div class="col-md-3">
                  <label for="valor_unitario" class="col-md-12"
                    >PDV adicional Valor:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.pdv_adicional_valor"
                    placeholder="PDV adicional Valor"
                  />
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label for="valor_unitario" class="col-md-12"
                    >URL do Link de Comprar TitanPass:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.sc_interno"
                    placeholder="colocar link site vendas TitanPass"
                  />
                </div>
                <div class="col-md-12 mt-4">
                  <label for="valor_unitario" class="col-md-12"
                    >URL do Link de Comprar Terceiros:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.sc_externo"
                    placeholder="colocar link site vendas terceiros"
                  />
                </div>
                <div class="col-md-12 mt-4">
                  <label for="valor_unitario" class="col-md-12"
                    >Mapa do evento
                  </label>

                  <iframe
                    width="100%"
                    height="450"
                    style="border: 0"
                    loading="lazy"
                    allowfullscreen
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="
                      'https://www.google.com/maps/embed/v1/place?key=AIzaSyCVLwaGKu4EOleaGwMAfncO1bB2YM23ZGs&q='+
                      form.localizacao
                    "
                  >
                  </iframe>
                </div>
              </div>

              <div class="row form-group col-md-12">
                <div class="col-md-10 pull-left">
                  <div class="md-radio-inline text-uppercase">
                    <label>Status:*</label>
                    <div class="space d-flex">
                      <b-form-radio
                        :inline="true"
                        value="2"
                        v-model="form.status"
                      >
                        Desativado
                      </b-form-radio>
                      <b-form-radio
                        :inline="true"
                        value="1"
                        v-model="form.status"
                      >
                        ativo
                      </b-form-radio>

                      <b-form-radio
                        :inline="true"
                        value="3"
                        v-model="form.status"
                      >
                        suspenso
                      </b-form-radio>

                      <b-form-radio
                        :inline="true"
                        value="5"
                        v-model="form.status"
                      >
                        Finalizar
                      </b-form-radio>
                      <b-form-radio
                        :inline="true"
                        value="4"
                        v-model="form.status"
                      >
                        Concluido
                      </b-form-radio>
                      <b-form-radio
                        :inline="true"
                        value="6"
                        v-model="form.status"
                      >
                        preview
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  {{ lista_campos == "" ? "Salvar" : "Atualizar" }}
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div></div
      ></v-card>
      <v-card class="mt-5" elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">
              {{ lista_campos != "" ? "Editar |" : "Criar |" }} Imagens E Video
            </h3>
          </h3></v-card-title
        >
        <div class="card-body table-responsive">
          <div class="col-md-9">
            <div class="row form-group">
              <InputFile text="Documento Banner pricipal*" ref="principal" />
              <div class="col-md-12 font-weight">
                Imagem logo Principal
                <v-img
                  max-height="300"
                  max-width="275"
                  :src="host + lista_campos.arquivos_id + '?token=' + token"
                ></v-img>
              </div>
            </div>
            <div class="row form-group">
              <input
                type="text"
                class="form-control"
                v-model="form.banner_id"
                placeholder="colocar link banner principal"
              />
              <div class="col-md-12 font-weight">
                Imagem Banner atual
                <v-img
                  max-height="300"
                  max-width="275"
                  :src="lista_campos.banner_id"
                ></v-img>
              </div>
            </div>
            <div class="row form-group">
              <input
                type="text"
                class="form-control"
                v-model="form.fly1"
                placeholder="colocar link fly1"
              />
              <div class="col-md-12 font-weight">
                Imagem fly1
                <v-img
                  max-height="300"
                  max-width="275"
                  :src="lista_campos.fly1"
                ></v-img>
              </div>
            </div>
            <div class="row form-group">
              <input
                type="text"
                class="form-control"
                v-model="form.fly2"
                placeholder="colocar link fly2"
              />
              <div class="col-md-12 font-weight">
                Imagem fly2
                <v-img
                  max-height="300"
                  max-width="275"
                  :src="lista_campos.fly2"
                ></v-img>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row form-group">
              <InputFile text="Documento Video *" ref="video" />
              <div class="col-md-12 font-weight">
                Video atual
                <br />
                <iframe
                  v-if="lista_campos.videos_id"
                  height="400"
                  width="375"
                  :src="host + lista_campos.videos_id + '?token=' + token"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  {{ lista_campos == "" ? "Salvar" : "Atualizar" }}
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </div></div
      ></v-card>

      <v-card class="mt-5" elevation="8">
        <v-card-title class="card-header">
          <h3 class="mb-0">
            <h3 class="mb-0">
              {{ lista_campos != "" ? "Editar |" : "Criar |" }} Ingressos e
              Ponto de venda
            </h3>
          </h3></v-card-title
        >
        <div class="card-body table-responsive">
          <div class="col-md-11">
            <div class="row form-group">
              <IngressosRegras></IngressosRegras>
            </div>
          </div></div
      ></v-card>
    </div>
  </div>
</template>

<script>
import IngressosRegras from "@/view/components/quil/IngressosRegra.vue";
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import InputFile from "@/core/helpers/InputFile";
import JwtService from "@/core/services/jwt.service";
import { t } from "caniuse-lite/data/browserVersions";
export default {
  components: { InputFile, IngressosRegras },

  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      verif: false,
      form_cat: { categorias: null, eventos_id: null },

      form: {
        nome_evento: null,
        produtora_produtor: null,
        cpf_cnpj: null,
        endereco_numero: null,
        cep: null,
        endereco: null,
        nome_local_evento: null,
        data_evento: null,
        horario_evento: null,
        classificacao: null,
        status: 1,
        cobranca: 2,
        porcetagem: 3,
        valor_unitario: null,
        valor_minimo: null,
        arquivos_id: null,
        fly1: null,
        fly2: null,
        videos_id: null,
        taxa_cartao_credito: null,
        taxa_cartao_debito: null,
        cidades_id: null,
        site: null,
        quant_pdv: null,
        pdv_adicional_valor: null,
        sc_interno: null,
        sc_externo: null,
        email: null,
        cel: "",
        localizacao: "",
        parceiros_id: null,
        hora_hide: null,
        date_hide: null,
        data_mais: null,
        tde: 1,
      },
      variableWithMask: "",
      paramestado: "",
      value: "",
      pais_id: null,
      loading: false,
      cpf_cnpj1: null,
      host: process.env.VUE_APP_API_URL + "arquivo/doc/",
      token: JwtService.getToken(),
    };
  },

  computed: {
    mensagem_alert() {
      return this.$store.state.evento.mensagem_alert;
    },
    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_estados() {
      return this.$store.state.endereco.lista_estados;
    },
    lista_cidades() {
      return this.$store.state.endereco.lista_cidades;
    },
    lista_categorias() {
      return this.$store.state.categoria.lista_categorias.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_parceiros() {
      return this.$store.state.parceiros.lista_parceiros.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_produtores() {
      return this.$store.state.configUsuarios.lista_produtores.map((cat) => ({
        id: cat.id,
        label: cat.nome +' '+cat.sobrenome,
      }));
    },
  },
  created() {
    this.preenxerCampor();
    this.$store.dispatch("endereco/listar_estados");
    this.$store.dispatch("categoria/listar_categorias");
    this.$store.dispatch("parceiros/listar_parceiros");
    this.$store.dispatch("configUsuarios/listar_produtores");

  },
  watch: {
    cpf_cnpj1: function () {
      this.variableWithMask =
        this.cpf_cnpj1.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form.cpf_cnpj = this.cpf_cnpj1.replace(/[^0-9]/g, "");
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Evento no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;

      if (this.$refs.principal) {
        this.form.arquivos_id = await this.$refs.principal.submit();
      }

      if (this.$refs.video) {
        this.form.videos_id = await this.$refs.video.submit();
      }

      await this.$store.dispatch("evento/create_evento", this.form);
      await this.$store.dispatch(
        "categoria/vinculo_categoria_evento",
        this.form_cat
      );

      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "evento",
      });
    },
    async update() {
      this.verif = true;

      if (this.$refs.principal) {
        this.form.arquivos_id = await this.$refs.principal.submit();
      }

      if (this.$refs.video) {
        this.form.videos_id = await this.$refs.video.submit();
      }
      await this.$store.dispatch("evento/update_evento", this.form);

      await this.$store.dispatch(
        "categoria/vinculo_categoria_evento",
        this.form_cat
      );

      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "evento",
      });
    },

    onInput(value) {
      this.$store.dispatch("endereco/listar_cidades", value);
    },
    onInput2(value) {
      if (value != 28) this.$store.dispatch("endereco/listar_estados", value);
      else this.$store.dispatch("endereco/listar_cidades", value);
    },
    loc() {
      for (let index = 0; index < this.lista_cidades.length; index++) {
        console.log(this.lista_cidades[index]["id"]);
        if (this.lista_cidades[index]["id"] == this.form.cidades_id) {
          this.nome_cidade = this.lista_cidades[index]["label"];
        }
      }
      if (this.form.endereco != "") {
        this.localizacao =
          this.nome_cidade + " " + this.form.endereco + " " + this.form.nome_local_evento;
      } else {
        this.localizacao = this.nome_cidade + " "+ this.form.nome_local_evento;
      }
      this.localizacao = this.localizacao
        .replace(/ /g, "+")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    this.form.localizacao = this.localizacao
    },

    async preenxerCampor() {
      if (this.lista_campos != "") {
        this.$store.dispatch(
          "endereco/listar_cidades",
          this.lista_campos.estados_id
        );

        this.form_cat.eventos_id = this.lista_campos.id;
        this.form_cat.categorias = this.lista_campos.categorias.map(
          (cat) => cat.categoria_id
        );
        this.form = {
          id: this.lista_campos.id,
          classificacao: this.lista_campos.classificacao,
          nome_evento: this.lista_campos.nome_evento,
          produtora_produtor: this.lista_campos.produtora_produtor,
          endereco_numero: this.lista_campos.endereco_numero,
          cep: this.lista_campos.cep,
          endereco: this.lista_campos.endereco,
          nome_local_evento: this.lista_campos.nome_local_evento,
          data_evento: this.lista_campos.data_evento,
          horario_evento: this.lista_campos.horario_evento,
          status: this.lista_campos.status,
          cobranca: this.lista_campos.cobranca,
          porcetagem: this.lista_campos.porcetagem,
          valor_unitario: this.lista_campos.valor_unitario,
          taxa_cartao_credito: this.lista_campos.taxa_cartao_credito,
          taxa_cartao_debito: this.lista_campos.taxa_cartao_debito,
          quant_pdv: this.lista_campos.quant_pdv,
          pdv_adicional_valor: this.lista_campos.pdv_adicional_valor,
          sc_interno: this.lista_campos.sc_interno,
          sc_externo: this.lista_campos.sc_externo,
          valor_minimo: this.lista_campos.valor_minimo,
          arquivos_id: this.lista_campos.arquivos_id,
          cidades_id: this.lista_campos.cidades_id,
          estados_id: this.lista_campos.estados_id,
          videos_id: this.lista_campos.videos_id,
          fly1: this.lista_campos.fly1,
          fly2: this.lista_campos.fly2,
          banner_id: this.lista_campos.banner_id,
          parceiros_id: this.lista_campos.parceiros_id,
          produtor_id: this.lista_campos.produtor_id,


          hora_hide: this.lista_campos.hora_hide,
          date_hide: this.lista_campos.date_hide,
          localizacao: this.lista_campos.localizacao,

          email: this.lista_campos.email,
          cel: this.lista_campos.cel,

          data_mais: this.lista_campos.data_mais,
          tde: this.lista_campos.tde,
        };
      }
      this.cpf_cnpj1 = this.lista_campos.cpf_cnpj;
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>